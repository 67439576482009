import React from 'react'

import '../styles/Education.scss'                                   //styles
import Header from '../components/Header'                           //components
import Footer from '../components/Footer'                           //
import { Badge, Container} from 'react-bootstrap'                   //
import { FiAlertTriangle, FiExternalLink } from "react-icons/fi"    //                 

const Education = (props) => {
    return(
        <div>
            <Header theme={props.theme} setTheme={props.setTheme} language={props.language} setLanguage={props.setLanguage}/>
            <div style={{marginTop:"100px"}} className="education-wrapper d-flex justify-content-center align-items-center">
                <Container>
                    <h1>This page is for my education related project</h1>
                    <FiAlertTriangle/><spam className="ms-2">English only content</spam>
                    <br/><br/><br/><br/>
                    <h4> 
                        <a href="https://ns-training.vercel.app/" target="_blank" rel="noreferrer">
                            Network Security trainer
                            <FiExternalLink className="ms-2"/>
                        </a>
                    </h4>
                    <h6>
                        <Badge pill bg="dark" className="text-light">public</Badge>
                        <Badge pill bg="dark" className="text-light ms-2">react</Badge>
                        <Badge pill bg="dark" className="text-light ms-2">js</Badge>
                    </h6>
                    
                    <p>
                        Knowledge testing tool for the Network Security exam in the University of Trento. <br/>
                        I collected questions and answers from past exams. Only educational porpouse. 
                    </p>
                    <br/><br/>
                    <h4>
                        <a href="https://github.com/RaffaeleCrocco/ecog-app" target="_blank" rel="noreferrer">
                            IoT device: EcoG 
                            <FiExternalLink className="ms-2"/>
                        </a>
                    </h4>
                    <h6>
                            <Badge pill bg="dark" className="text-light">public</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">react</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">js</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">c++</Badge>
                        </h6>
                    <p>
                        GitHub repository for my personal IoT project. <br/>
                        This smart pot connects to your local network and hosts sensor data for your plant. 
                    </p>
                    <br/><br/>
                    <h4>
                        <a href="https://github.com/RaffaeleCrocco/fundamental-of-robotic/blob/main/src/ur5/ur5_gazebo/scripts/vision.py" target="_blank" rel="noreferrer">
                            Computer Vision
                            <FiExternalLink className="ms-2"/>
                        </a>
                    </h4>
                    <h6>
                            <Badge pill bg="dark" className="text-light">public</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">phyton</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">machine learning</Badge>
                            <Badge pill bg="dark" className="text-light ms-2">robotic</Badge>
                        </h6>
                    <p>
                        Computer vision script made to recognize and classify lego on a table <br/>
                        so a simulated ROS controlled robotic arm can handle them. 
                    </p>
                </Container>
            </div>
            <Footer language={props.language}/>
        </div>
    );
};

export default Education