import React from 'react';

import Paragraph from "./Paragraph.js"                                //components
import { Container, Row, Col } from 'react-bootstrap'                 //
import data from '../data/data.json'                                  //data
import '../styles/Introduction.scss'                                  //styles
import introductionLight from '../media/svg/introduction-light.svg'   //images
import introductionDark from '../media/svg/introduction-dark.svg'     //

const Introduction= (props) => {
    return(
        <div className="introduction-wrapper d-flex justify-content-center align-items-end">
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        <div className="intro-wrapper">
                            <Row>
                                <Col className="m-0 p-0 d-flex justify-content-center" md={'auto'}>
                                    <img src={props.theme === "light" ? introductionLight : introductionDark} className="introduction-img" alt="Every project as a chapter"/> 
                                </Col>
                                <Col className="m-5">
                                    <Paragraph
                                        subtitle={data[props.language].subtitles[5]}
                                        title={data[props.language].titles[6]}
                                        text={data[props.language].texts[6]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col> 
                </Row>
            </Container>
            <hr/>
        </div>
    );
};

export default Introduction;