import React from 'react'

import '../styles/Footer.scss'                          //styles
import data from '../data/data.json'                    //data
import Container from 'react-bootstrap/Container'       //components
import { Row, Col } from 'react-bootstrap'              //
import { NavHashLink } from 'react-router-hash-link'    //router

const Footer = (props) => {
    return(
        <div className="footer-wrapper">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                        <ul>
                            <p className="fw-bold">&#8618; {data[props.language].footer.titles[0]}</p>
                            <NavHashLink smooth to={data[props.language].footer.cols.c1[0].link}>
                                <li>{data[props.language].footer.cols.c1[0].display}</li>
                            </NavHashLink>
                            <NavHashLink smooth to={data[props.language].footer.cols.c1[1].link}>
                                <li>{data[props.language].footer.cols.c1[1].display}</li>
                            </NavHashLink>
                            <NavHashLink smooth to={data[props.language].footer.cols.c1[2].link}>
                                <li>{data[props.language].footer.cols.c1[2].display}</li>
                            </NavHashLink>
                            <NavHashLink smooth to={data[props.language].footer.cols.c1[3].link}>
                                <li>{data[props.language].footer.cols.c1[3].display}</li>
                            </NavHashLink>
                            <NavHashLink smooth to={data[props.language].footer.cols.c1[4].link}>
                                <li>{data[props.language].footer.cols.c1[4].display}</li>
                            </NavHashLink>
                        </ul>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                        <ul>
                            <p className="fw-bold">&#8618; {data[props.language].footer.titles[1]}</p>
                            <a href={data[props.language].footer.cols.c2[0].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c2[0].display}</li>
                            </a>
                            <a href={data[props.language].footer.cols.c2[1].link}>
                                <li>{data[props.language].footer.cols.c2[1].display}</li>
                            </a>
                        </ul>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                        <ul>
                            <p className="fw-bold">&#8618; {data[props.language].footer.titles[2]}</p>
                            <a href={data[props.language].footer.cols.c3[0].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c3[0].display}</li>
                            </a>
                            <a href={data[props.language].footer.cols.c3[1].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c3[1].display}</li>
                            </a>
                            <a href={data[props.language].footer.cols.c3[2].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c3[2].display}</li>
                            </a>
                            <a href={data[props.language].footer.cols.c3[3].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c3[3].display}</li>
                            </a>
                        </ul>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                        <ul>
                            <p className="fw-bold">&#8618; {data[props.language].footer.titles[3]}</p>
                            <a href={data[props.language].footer.cols.c4[0].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c4[0].display}</li>
                            </a>
                            <a href={data[props.language].footer.cols.c4[1].link} target="_blank" rel="noreferrer">
                                <li>{data[props.language].footer.cols.c4[1].display}</li>
                            </a>
                        </ul>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                        <ul>
                            <p className="fw-bold">&#8618; {data[props.language].footer.titles[4]}</p>
                            <li>&copy; {data[props.language].footer.cols.c5[0].display}</li>
                            <li>{data[props.language].footer.cols.c5[1].display}</li>
                            <li>
                                <a href={data[props.language].footer.cols.c5[2].link} target="_blank" rel="noreferrer">
                                    {data[props.language].footer.cols.c5[2].display}
                                </a>
                            </li>
                        </ul>
                    </Col>  
                </Row>
            </Container>
        </div>
    );
};

export default Footer