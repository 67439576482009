import React from 'react';

import Paragraph from "./Paragraph.js"                       //components
import { Container, Row, Col } from 'react-bootstrap'        //
import ButtonCustom from './ButtonCustom.js'                 //
import data from '../data/data.json'                         //data
import '../styles/ChapterThree.scss'                         //styles
import Fade from 'react-reveal/Fade'                         //


const ChapterThree = (props) => {
    
    //for the embedded video
    const youtubeID = 'AZ23G6drYK4'

    //props and settings for the button component
    const buttons = [
        {
            "data" : data[props.language].buttons[7].display, 
            "variant" : props.theme === "light" ? "outline-light" : "outline-dark",
            "href" : data[props.language].buttons[7].href
        }
    ]

    return (
        <div className="chapterthree-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    
                    <Col md={6}  className="d-flex justify-content-center align-items-center">
                        <Fade left>
                            <div>
                                <Paragraph
                                    subtitle={data[props.language].subtitles[8]}
                                    title={data[props.language].titles[9]}
                                    text={data[props.language].texts[9]}
                                />
                                <ButtonCustom
                                    buttons={buttons}
                                />
                            </div>                                                  
                        </Fade>
                    </Col>
                    <Col xs={{ order: 'first' }} md={{cols: 6, order: 'last' }}  className="d-flex justify-content-center align-items-center">
                        <Fade>
                            <iframe 
                                className="chapterthree-img"
                                title='Youtube player'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
                            </iframe>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChapterThree;