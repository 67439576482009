import React from 'react'

import '../styles/Main.scss'                           //styles
import Header from '../components/Header'              //components
import Introduction from '../components/Introduction'  //
import ChapterOne from '../components/ChapterOne'      //
import ChapterFour from '../components/ChapterFour'    //
import ChapterTwo from '../components/ChapterTwo'      //
import ChapterThree from '../components/ChapterThree'  //
import Contact from '../components/Contact'            //
import Footer from '../components/Footer'              //

const Projects = (props) => {
    return(
        <div className="projects-wrapper">
            <Header theme={props.theme} setTheme={props.setTheme} language={props.language} setLanguage={props.setLanguage}/>
            <a id="projects" className="anchor" href="#projects"><div></div></a> {/*the empty div and the usless href are needed to avoid warnings*/}
            <Introduction theme={props.theme} language={props.language}/>
            <ChapterOne theme={props.theme} language={props.language}/>
            <ChapterTwo theme={props.theme} language={props.language}/>
            <ChapterThree theme={props.theme} language={props.language}/>
            <ChapterFour theme={props.theme} language={props.language}/>
            <Contact theme={props.theme} language={props.language} parent="Projects"/>
            <Footer language={props.language}/>
        </div>
    );
};

export default Projects