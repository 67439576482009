import React from "react";

//styles
import './styles/theme.scss';  //light and dark theme for the app
import './styles/App.scss';

//components
import Main from "./pages/Main";
import Projects from "./pages/Projects";
import Education from "./pages/Education";
import PageNotFound from "./pages/PageNotFound";

//hooks
import useLocalStorage from "./hooks/useLocalStorage";
import useThemeDetector from "./hooks/useThemeDetector";

//from library
import getUserLocale from 'get-user-locale'

//router
import {Route, BrowserRouter as Router, Switch } from "react-router-dom"


function App() {

  //
  //state for light and dark mode
  //

  //setting as a default the one user have in system preferences
  const isDarkTheme = useThemeDetector();
  var defaultTheme;
  isDarkTheme ? defaultTheme="dark": defaultTheme="light"
  

  //setting theme as saved in local storage or default
  const [theme, setTheme] = useLocalStorage('theme', defaultTheme)
  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }

  //
  //state for languages
  //

  //setting as a default the one user have in system preferences
  const userLocale = getUserLocale().substring(0,2);
  var defaultLanguage;
  userLocale === 'it' ? defaultLanguage='it' : defaultLanguage='en'

  //state for language
  const [language, setLanguage] = useLocalStorage('language', defaultLanguage)
  const changeLanguage = () => {
    if (language === 'en') {
      setLanguage('it');
    } else {
      setLanguage('en');
    }
  }

  return (
    <div className={`App ${theme}`}>
      <Router>
        <Switch>
          <Route path="/" exact render={() => <Main theme={theme} setTheme={toggleTheme} language={language} setLanguage={changeLanguage} />}/>
          <Route path="/projects" render={() => <Projects theme={theme} setTheme={toggleTheme} language={language} setLanguage={changeLanguage} />}/>
          <Route path="/education" render={() => <Education theme={theme} setTheme={toggleTheme} language={language} setLanguage={changeLanguage} />}/>
          <Route render={() => <PageNotFound theme={theme} setTheme={toggleTheme} language={language} setLanguage={changeLanguage} />}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
