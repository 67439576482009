import React from 'react';

import Paragraph from "./Paragraph.js"                    //components
import { Container, Row, Col } from 'react-bootstrap'     //
import ButtonCustom from './ButtonCustom.js'              //
import data from '../data/data.json'                      //data
import '../styles/ChapterFour.scss'                       //styles
import Fade from 'react-reveal/Fade'                      //
import cv from '../media/png/cv.png'    //images


const ChapterFour = (props) => {
    
    //props and settings for the button component
    const buttons = [
        {
            "data" : data[props.language].buttons[8].display, 
            "variant" : props.theme === "light" ? "outline-light" : "outline-dark",
            "href" : data[props.language].buttons[8].href
        }
    ]

    return (
        <div className="chapterfour-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col md={6} className="d-flex justify-content-center align-items-center">
                        <Fade>
                            <img src={cv} className="chapterfour-img" alt="e-commerce made with React JS"/>
                        </Fade>
                    </Col>
                    <Col md={6}  className="d-flex justify-content-center align-items-center">
                        <Fade left>
                            <div>
                                <Paragraph
                                    subtitle={data[props.language].subtitles[9]}
                                    title={data[props.language].titles[10]}
                                    text={data[props.language].texts[10]}
                                />
                                <ButtonCustom
                                    buttons={buttons}
                                />
                            </div>                                                  
                        </Fade>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    );
};

export default ChapterFour;