import React from 'react';

import Button from 'react-bootstrap/Button'   //components

const ButtonCustom = (props) => {
    return (
        <div className='mb-4'>
            <a href={props.buttons[0].href} target="_blank" rel="noreferrer">
                {
                    props.buttons[0].variant === "primary"
                    ?
                    <Button variant={props.buttons[0].variant} className="button"  style={{border:"1px white solid"}}>{props.buttons[0].data}</Button>
                    :
                    <Button variant={props.buttons[0].variant} className="button">{props.buttons[0].data}</Button>
                }
                   
            </a>
            
            {
               props.buttons[1] ?
                <a href={props.buttons[1].href} target="_blank" rel="noreferrer">
                   <Button variant={props.buttons[1].variant} className="button ms-2">{props.buttons[1].data}</Button>
                </a> :
                ""
            }
        </div>
    );
};

export default ButtonCustom;