import React from 'react'

import '../styles/Main.scss'                 //styles
import Home from '../components/Home'        //components
import About from '../components/About'      //
import Work from '../components/Work'        //
import Skill from '../components/Skill'      //
import Contact from '../components/Contact'  //
import Footer from '../components/Footer'    //

const Main = (props) => {
    return(
        <div className="">
            <a id="home" className="anchor" href="#home"><div></div></a> {/*the empty div and the usless href are needed to avoid warnings*/}
            <Home theme={props.theme} setTheme={props.setTheme} language={props.language} setLanguage={props.setLanguage}/>
            <a id="about" className="anchor" href="#about"><div></div></a> {/*the empty div and the usless href are needed to avoid warnings*/}
            <About theme={props.theme} language={props.language}/>
            <a id="work" className="anchor" href="#work"><div></div></a> {/*the empty div and the usless href are needed to avoid warnings*/}
            <Work theme={props.theme} language={props.language}/>
            <Skill theme={props.theme} language={props.language}/>
            <Contact theme={props.theme} language={props.language} parent="Main"/>
            <Footer language={props.language}/>
        </div>
    );
};

export default Main