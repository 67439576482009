import React from 'react';

import '../styles/Paragraph.scss'           //styles
import Markdown from 'react-markdown'       //data string styling

const Paragraph = (props) => {
    return(
        <div className="paragraph-wrapper">
            {
                props.subtitle ?
                <p className="subtitle"><span>&lsquo; </span>{props.subtitle}<span> &sbquo;</span></p> : 
                ""
            }   
            {
                props.highlighted ?
                <p className="title"><Markdown>{props.title}</Markdown><spam class="highlighted-text">{props.highlighted}</spam></p> :
                <p className="title"><Markdown>{props.title}</Markdown></p>
            }
            <p className="text"><Markdown>{props.text}</Markdown></p>
        </div>
    );
};

export default Paragraph