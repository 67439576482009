import {React, useState} from "react";

import '../styles/Social.scss'                    //styles
import { SocialIcon } from 'react-social-icons'   //icon pakage for socials
import Modal from 'react-bootstrap/Modal'         //component
import data from '../data/data.json'              //data

const Social = (props) => {
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* visible part in the menu before click */}
            <small onClick={handleShow}> {data[props.language].footer.cols.c1[5].display} </small>
            {/* modal that show itself after click */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {data[props.language].footer.cols.c1[5].display} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-around w-100">
                        <SocialIcon url="https://github.com/RaffaeleCrocco" target="_blank" rel="noreferrer"/>
                        <SocialIcon url="https://www.linkedin.com/in/raffaelecrocco" target="_blank" rel="noreferrer"/>
                        {/* <SocialIcon url="https://discord.com/in/jaketrent" target="_blank" rel="noreferrer"/> */}
                        <SocialIcon url="https://t.me/raffaelecrocco" network="telegram" target="_blank" rel="noreferrer"/> 
                        <SocialIcon url="https://www.instagram.com/raffaelecrocco/" target="_blank" rel="noreferrer"/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Social;