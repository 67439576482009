import React from "react";

import "../styles/Home.scss"; //styles
import Fade from "react-reveal/Fade"; //
import { Link } from "react-router-dom"; //router
import Header from "./Header.js"; //components
import Paragraph from "./Paragraph.js"; //
import { Container, Row, Col } from "react-bootstrap"; //
import data from "../data/data.json"; //data
import imgLight from "../media/svg/televisions-light.svg"; //images
import imgDark from "../media/svg/televisions-dark.svg"; //
import personalLight from "../media/svg/personal-photo-light.svg"; //
import personalDark from "../media/svg/personal-photo-dark.svg"; //
import ButtonCustom from "./ButtonCustom"; //

const Home = (props) => {
  //props and settings for the button component
  const buttons = [
    {
      data: data[props.language].buttons[0].display,
      variant: "primary",
    },
  ];

  return (
    <div className="home-wrapper d-flex justify-content-center align-items-center">
      <Container>
        <Header
          theme={props.theme}
          setTheme={props.setTheme}
          language={props.language}
          setLanguage={props.setLanguage}
        />
        <Row>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Fade left>
              <div>
                <Paragraph
                  subtitle={data[props.language].subtitles[0]}
                  title={data[props.language].titles[0]}
                  text={data[props.language].texts[0]}
                  // highlighted={data[props.language].highlights[0]}
                />
                <Link to={data[props.language].buttons[0].link}>
                  <ButtonCustom buttons={buttons} />
                </Link>
              </div>
            </Fade>
          </Col>

          <Col
            xs={{ order: "first" }}
            md={{ cols: 6, order: "last" }}
            className="d-flex align-content-center justify-content-center"
          >
            <Fade>
              <img
                src={props.theme === "light" ? imgLight : imgDark}
                className="home-img d-none d-md-block"
                alt="My creative process"
              />
              <img
                src={props.theme === "light" ? personalLight : personalDark}
                className="home-img d-none d-md-none"
                alt="Raffaele Crocco"
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
