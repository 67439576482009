import React from 'react'

import '../styles/Skill.scss'                              //styles
import Fade from 'react-reveal/Fade'                       //
import data from '../data/data.json'                       //data
import Paragraph from "./Paragraph.js"                     //components
import { Container, Row, Col } from 'react-bootstrap'      //
import imgLight from '../media/svg/skills-light.svg'       //images
import imgDark from '../media/svg/skills-dark.svg'         //
import ReactPlayer from 'react-player'                     //videos
import videocourse from '../media/video/videocourse.mp4'   //

const Skill = (props) => {
    return(
        <div className="skill-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col md={6} className="d-flex justify-content-center align-items-end" >
                        <Fade left>
                            <Paragraph
                                title={props.language === "en" ? data.en.titles[3] : data.it.titles[3]}
                                text={props.language === "en" ? data.en.texts[3] : data.it.texts[3]}
                            />
                        </Fade>
                    </Col>
                    <Col xs={{ order: 'first' }} md={{cols: 6, order: 'last' }} className="d-flex justify-content-center align-items-center">
                        <Fade>
                            <img src={props.theme === "light" ? imgLight : imgDark} className="skill-img" alt="A lot more then a web developer"/>
                        </Fade>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="d-flex justify-content-center align-items-center">
                        <div className="skill-video">
                            <ReactPlayer 
                                url={videocourse}
                                controls="true"
                                width="100%"
                                height="100%"   
                            />
                        </div>
                    </Col>
                    <Col md={6} className="d-flex justify-content-center align-items-center" style={{paddingTop:'40px'}}>
                        <Fade right>
                            <Paragraph
                                title={props.language === "en" ? data.en.titles[4] : data.it.titles[4]}
                                text={props.language === "en" ? data.en.texts[4] : data.it.texts[4]}
                            />
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Skill