import React from 'react'

import '../styles/Contact.scss'                                          //styles
import Fade from 'react-reveal/Fade'                                     //
import data from '../data/data.json'                                     //data
import Paragraph from "./Paragraph.js"                                   //components
import ButtonCustom from './ButtonCustom'                                //
import { Container, Row, Col, Form } from 'react-bootstrap'              //
import contactPunchLight from '../media/svg/contact-punch-light.svg'     //images
import contactPunchDark from '../media/svg/contact-punch-dark.svg'       //
import contactStairsLight from '../media/svg/contact-stairs-light.svg'   //
import contactStairsDark from '../media/svg/contact-stairs-dark.svg'     //

const Contact = (props) => {
     //props and settings for the button component
     const buttons = [
        {
            "data" : data[props.language].buttons[3].display, 
            "variant" : "primary",
            "href" : data[props.language].buttons[3].href
        }
    ]
    return(
        <div className="contact-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col md={6}  className="d-flex justify-content-center align-items-center">
                        <Fade left>
                            <div style={{paddingLeft:'20px'}}>
                                {/* this paragraph title and img depends on parent */}
                                <Paragraph
                                    subtitle={data[props.language].subtitles[4]}
                                    title={
                                        props.parent === "Main" ?
                                        data[props.language].titles[5] :
                                        data[props.language].titles[11]
                                    }
                                    text={data[props.language].texts[5]}
                                    highlighted={
                                        props.parent === "Main" ?
                                        data[props.language].highlights[1]:
                                        ""                                    
                                    }
                                    square="true"
                                /> 
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Control type="email" placeholder={data[props.language].buttons[3].placeholder} />
                                        </Col>
                                        <Col>
                                            <ButtonCustom 
                                                buttons={buttons}
                                            />  
                                        </Col>
                                    </Row>
                                </Form>
                            </div>                                                  
                        </Fade>
                    </Col>
                    <Col xs={{ order: 'first' }} md={{cols: 6, order: 'last' }} className="d-flex justify-content-center align-items-center">
                        <Fade>
                            {
                                props.parent === "Main" ?
                                <img src={props.theme === "light" ? contactPunchLight : contactPunchDark} className="contact-img" alt="Let's get in touch" /> :
                                <img src={props.theme === "light" ? contactStairsLight : contactStairsDark} className="contact-img" alt="Project process" />
                            }
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contact