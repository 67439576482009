import React from 'react'
import '../styles/Work.scss'                               //styles
import Fade from 'react-reveal/Fade'                       //
import data from '../data/data.json'                       //data
import Paragraph from "./Paragraph.js"                     //components
import { Container, Row, Col } from 'react-bootstrap'      //
import Card from './Card'                                  //
import imgLight from '../media/svg/work-light.svg'         //images
import imgDark from '../media/svg/work-dark.svg'           //

const Work = (props) => {
    return(
        <div className="work-wrapper d-flex justify-content-center align-items-center">
            <Container>
                    <Row>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <Fade left>
                                <div>
                                    <Paragraph
                                        subtitle={props.language === "en" ? data.en.subtitles[2] : data.it.subtitles[2]}
                                        title={props.language === "en" ? data.en.titles[2] : data.it.titles[2]}
                                        text={props.language === "en" ? data.en.texts[2] : data.it.texts[2]}
                                    />
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={{ order: 'first'}} md={{cols: 6, order: 'last' }} className="d-flex justify-content-center align-items-center">
                            <Fade>
                                <img src={props.theme === "light" ? imgLight : imgDark} className="work-img" alt="Work of art"/> 
                            </Fade>                 
                        </Col>
                    </Row> 
                    <Row className="d-none d-md-flex">
                        <Col xl={4} lg={4} md={12} style={{paddingTop:'10px'}}>
                            <Card
                                title={data[props.language].cards[0].title}
                                text={data[props.language].cards[0].content}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={12} style={{paddingTop:'10px'}}>
                            <Card
                                title={data[props.language].cards[1].title}
                                text={data[props.language].cards[1].content}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={12} style={{paddingTop:'10px'}}>
                            <Card
                                title={data[props.language].cards[2].title}
                                text={data[props.language].cards[2].content}
                            />
                        </Col>
                    </Row>
           </Container>
        </div>
    );
};

export default Work