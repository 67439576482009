import React from 'react';

import Paragraph from "./Paragraph.js"                     //components
import { Container, Row, Col } from 'react-bootstrap'      //
import data from '../data/data.json'                       //data
import '../styles/ChapterOne.scss'                         //styles
import Fade from 'react-reveal/Fade'                       //
import helpukrainians from '../media/png/help-ukrainians.png'       //images
import ButtonCustom from './ButtonCustom.js'               //

const ChapterOne = (props) => {

    //props and settings for the button component
    const buttons = [
        {
            "data" : data[props.language].buttons[4].display, 
            "variant" : "primary",
            "href": data[props.language].buttons[4].href,
        },
        {
            "data" : data[props.language].buttons[5].display, 
            "variant" : props.theme === "light" ? "outline-light" : "outline-dark",
            "href": data[props.language].buttons[5].href,
        }
    ]

    return (
        <div className="chapterone-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    
                    <Col md={6}  className="d-flex justify-content-center align-items-center">
                        <Fade left>
                            <div>
                                <Paragraph
                                    subtitle={data[props.language].subtitles[6]}
                                    title={data[props.language].titles[7]}
                                    text={data[props.language].texts[7]}
                                />                    
                                <ButtonCustom
                                    buttons = {buttons}
                                />
                            </div>                                                  
                        </Fade>
                    </Col>
                    <Col xs={{ order: 'first' }} md={{cols: 6, order: 'last' }}  className="d-flex justify-content-center align-items-center">
                        <Fade>
                            <img src={helpukrainians} className="chapterone-img" alt="help-ukrainians.org"/>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChapterOne;