import React from 'react'

import Header from '../components/Header'                    //components
import Footer from '../components/Footer'                    //
import { Container, Row, Col } from 'react-bootstrap'        //
import '../styles/PageNotFound.scss'                         //styles
import PageNotFoundLight from '../media/svg/404-light.svg'   //images
import PageNotFoundDark from '../media/svg/404-dark.svg'     //             

const PageNotFound = (props) => {
    return(
        <div>
            <Header theme={props.theme} setTheme={props.setTheme} language={props.language} setLanguage={props.setLanguage}/>
            <div style={{minHeight: "100vh"}} className="d-flex justify-content-center align-items-center">
                <Container>
                    <Row>
                        <Col md="6">
                            <img src={props.theme === "light" ? PageNotFoundLight : PageNotFoundDark} alt="Error 404" style={{width:"80%"}}/> 
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <div>
                                <h1>Oopsie Woopsie</h1>
                                <h4>404 Page not found</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer language={props.language}/>
        </div>
    );
};

export default PageNotFound