import React from 'react';

import Paragraph from "./Paragraph.js"                            //components
import { Container, Row, Col } from 'react-bootstrap'             //
import data from '../data/data.json'                              //data
import '../styles/ChapterTwo.scss'                                //styles
import Fade from 'react-reveal/Fade'                              //
import ns from '../media/png/ns.png'                              //images
import ButtonCustom from './ButtonCustom.js';

const ChapterTwo = (props) => {

    //props and settings for the button component
    const buttons = [
        {
            "data" : data[props.language].buttons[6].display, 
            "variant" : props.theme === "light" ? "outline-light" : "outline-dark",
            "href" : data[props.language].buttons[6].href
        }
    ]

    return (
        <div className="chaptertwo-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        <Fade>
                            <img src={ns} className="chaptertwo-img" alt="Scalable and responsive content"/>
                        </Fade>
                    </Col>
                    <Col md={6}  className="d-flex justify-content-center align-items-center">
                        <Fade left>
                            <div>
                                <Paragraph
                                    subtitle={data[props.language].subtitles[7]}
                                    title={data[props.language].titles[8]}
                                    text={data[props.language].texts[8]}
                                    highlighted={data[props.language].highlights[2]}
                                    square="true"
                                />
                                <ButtonCustom
                                    buttons={buttons}
                                />
                            </div>                                                  
                        </Fade>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    );
};

export default ChapterTwo;