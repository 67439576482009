import React from 'react';

import '../styles/Card.scss'             //styles
import Markdown from 'react-markdown'    //data string styling

const Card = (props) => {
    return(
        <div className="card-wrapper vertical-center">
            <p className="card-title"><span>----- </span><Markdown>{props.title}</Markdown></p> 
            <p className="card-text"><Markdown>{props.text}</Markdown></p>
        </div>
    );
};

export default Card