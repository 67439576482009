import React from 'react'

import '../styles/About.scss'                          //styles
import Fade from 'react-reveal/Fade'                   //
import data from '../data/data.json'                   //data
import Paragraph from "./Paragraph.js"                 //components
import Container from 'react-bootstrap/Container'      //
import Row from 'react-bootstrap/Row'                  //
import Col from 'react-bootstrap/Col'                  //
import imgLight from '../media/svg/about-light.svg'    //images
import imgDark from '../media/svg/about-dark.svg'      //
import ButtonCustom from './ButtonCustom'              //
import curriculum from '../media/pdf/CV.pdf'           //pdf

const About = (props) => {
    
    //props and settings for the button component
    const buttons = [
        {
            "data" : data[props.language].buttons[1].display, 
            "variant" : props.theme === "light" ? "outline-light" : "outline-dark"
        }
    ]

    return(
        <div className="about-wrapper d-flex justify-content-center align-items-center">
             <Container>
                    <Row>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <Fade>
                                <img src={props.theme === "light" ? imgLight : imgDark} className="about-img" alt="About me, Raffaele"/>                  
                            </Fade>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <Fade right>
                                <div>
                                    <Paragraph
                                        subtitle={data[props.language].subtitles[1]}
                                        title={data[props.language].titles[1]}
                                        text={data[props.language].texts[1]}
                                    />
                                    <a href={curriculum} target="_blank" rel="noreferrer">
                                        <ButtonCustom 
                                            buttons={buttons}
                                        />
                                    </a>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
            </Container>
        </div>
    );
};

export default About