import React from 'react'

import '../styles/Header.scss'                                  //styles
import '../styles/theme-switch.scss'                            //
import logoLight from '../media/logo/logo-light.svg'            //logo
import logoDark from '../media/logo/logo-dark.svg'              //
import data from '../data/data.json'                            //data
import { FiMoon, FiSun } from "react-icons/fi"                  //icons
import {Navbar, Nav, Container, Badge} from 'react-bootstrap'   //components
import Social from './Social'                                   //
import { NavHashLink } from 'react-router-hash-link'            //router


const Header = (props) => {

    //change theme (light and dark mode)
    const toggleTheme = () => {
        if (props.theme === 'light') {
            props.setTheme('dark')
        } else {
            props.setTheme('light')      
        }
    }

    //change language
    const changeLanguage = () => {
        if (props.language === 'en') {
            props.setLanguage('it');
        } else {
            props.setLanguage('en');
        }
    }

    return(
        <div className="header-wrapper">
            <Container>
                <Navbar collapseOnSelect expand="lg" fixed="top" bg={props.theme === "light" ? "bg-light" : "bg-dark"}>
                    <NavHashLink smooth to={data[props.language].menu[0].link}>
                        <Navbar.Brand href="#default" className="cursor-pointer">
                            <img alt="Raffaele Crocco" src={props.theme === "light" ? logoLight : logoDark} width="50" height="50" className="d-inline-block align-top"/>
                        </Navbar.Brand>
                    </NavHashLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                        <Nav className="ms-auto me-auto ">
                            <NavHashLink smooth to={data[props.language].menu[0].link}>
                                <Nav.Link href="#default"><small>{data[props.language].menu[0].display}</small></Nav.Link>
                            </NavHashLink >
                            <NavHashLink smooth to={data[props.language].menu[1].link}>
                                <Nav.Link href="#default"><small>{data[props.language].menu[1].display}</small></Nav.Link>
                            </NavHashLink>
                            <NavHashLink smooth to={data[props.language].menu[2].link}>
                                <Nav.Link href="#default"><small>{data[props.language].menu[2].display}</small></Nav.Link>
                            </NavHashLink >
                            <NavHashLink smooth to={data[props.language].menu[3].link}>
                                <Nav.Link href="#default"><small>{data[props.language].menu[3].display}</small></Nav.Link>
                            </NavHashLink >
                            {/* <NavHashLink smooth to={data[props.language].menu[4].link}>
                                <Nav.Link href="#default"><small>{data[props.language].menu[4].display}</small></Nav.Link>
                            </NavHashLink > */}
                            <Nav.Link href="#default">
                                <Social language={props.language}/>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={changeLanguage}>
                                {props.theme === 'dark' ? 
                                    <Badge pill text="dark">
                                        <small>{props.language === "en" ? "it" : "en"}</small>
                                    </Badge>
                                : 
                                    <Badge pill text="light">
                                        <small>{props.language === "en" ? "it" : "en"}</small>
                                    </Badge>
                                }
                            </Nav.Link>
                            
                                <Nav.Link href="#deets">
                                    <label class="switch" for="checkbox" title="Change color scheme to dark mode" >
                                        {props.theme === "dark" ? 
                                            <input type="checkbox" id="checkbox" onClick={toggleTheme} checked/>
                                        : 
                                            <input type="checkbox" id="checkbox" onClick={toggleTheme}/> 
                                        }
                                        <div class="slider round cursor-pointer"></div>
                                        <div class="toggle-moon cursor-pointer"><FiSun/></div>
                                        <div class="toggle-sun cursor-pointer"><FiMoon/></div>

                                        {/* circle shape */}
                                        <div id="circle" class="circle"></div>
                                    </label>
                                </Nav.Link>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
         </div>
    );
};

export default Header